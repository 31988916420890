html {
  background: linear-gradient(#020167, #f2fbff);
  overflow: hidden; }

body {
  line-height: 1.4;
  color: #333;
  margin: 0;
  height: 100%;
  overflow: auto; }

.wrapper {
  max-width: 75rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 100vh;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1); }

.contact-bar {
  background: #020167;
  display: flex;
  justify-content: space-between;
  padding: 0 .5rem; }

.contact-bar a {
  padding: .5em 0;
  display: flex;
  line-height: 1; }

.contact-bar a, .nav a, .subnav a {
  color: #eee;
  display: inline-block;
  transform: translateZ(0);
  transition: color 1s; }

.contact-bar .icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  position: relative;
  bottom: -0.125em; }

.contact-bar a:hover {
  transform: scale(1.02);
  transform-origin: center center;
  color: #ff9; }

.title-wrap {
  display: block;
  background: #020167 url(../images/header1.jpg) repeat 0 0/cover;
  background-clip: border-box;
  padding: 0 .5rem; }

.header a {
  text-decoration: none; }

.title {
  font-family: serif;
  font-size: 2.2em;
  letter-spacing: -.015em;
  font-weight: bold;
  color: #ff9;
  display: flex;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-shadow: 1px 0 1px #000; }

.title span:nth-child(odd) {
  font-size: .7em; }

.title span:not(:nth-child(3)) {
  margin-right: .3em; }

.title span:nth-child(3) {
  margin-top: auto; }

.title span:nth-child(2) {
  line-height: .93; }

.subtitle {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 1.2em;
  letter-spacing: .11em;
  margin: 10px 0 0;
  padding-left: 115px;
  padding-bottom: 20px;
  background: url("../images/logo.jpg") no-repeat; }

.subtitle span {
  display: block; }

.nav {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  background-color: #020167;
  margin: 0;
  padding: 0; }

.nav li {
  min-width: 75px; }

.nav a {
  text-decoration: none;
  font-size: 1em;
  text-align: center;
  display: block;
  padding: .5em .5em;
  transition-duration: 300ms; }

.nav .active, .nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff; }

.main {
  padding: 1em .5em;
  background: rgba(255, 255, 255, 0.8); }

.footer {
  text-align: center;
  background: #020167;
  color: #fff;
  padding: .5em; }

.footer a {
  color: #ccc; }

.footer-contact {
  display: flex;
  justify-content: center; }

.footer-contact > * {
  padding: 0 2em; }

@media screen and (min-width: 30em) {
  .nav {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); } }

@media screen and (min-width: 40em) {
  body {
    font-size: 125%; }
  .main {
    padding: 1em; }
  .contact-bar, .title-wrap {
    padding: 0 1em; }
  .title {
    font-size: 3em;
    letter-spacing: 0; }
  .subtitle {
    font-size: 1.25em;
    letter-spacing: .2em;
    margin-top: 10px;
    padding-left: 130px; } }

.cards {
  display: grid;
  grid-gap: 1em 3%;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-bottom: 1rem; }

.cards img {
  max-width: 100%;
  border-radius: 3px 3px 0 0; }

.cards a {
  text-decoration: none;
  border-radius: 4px;
  transition: all .5s;
  border: 1px solid #020167;
  border: 1px solid rgba(2, 1, 103, 0.3);
  background-image: linear-gradient(180deg, #f4feff 0, #e2f9ff 50%, #f4feff); }

.cards a:hover {
  color: #020167;
  border: 1px solid #020167;
  background: #fff; }

.card-text {
  padding: 1em 1em 0; }

.cards h4 {
  font-size: 130%;
  font-weight: normal;
  text-decoration: underline; }

.main .card {
  color: #333; }

/*! Flickity v2.1.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }


*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

article, aside, dialog, figcaption, figure, footer, header, main, nav, section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

blockquote, dl, figure, h1, h2, h3, h4, h5, h6, ol, p, ul {
  margin: 0 0 1rem; }

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

b, strong {
  font-weight: bolder; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  vertical-align: middle;
  border-style: none; }

[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

h1, h2, h3, h4, h5 {
  font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  margin-top: 0;
  margin-bottom: .5rem;
  line-height: 1.4; }

h3 {
  color: #020167;
  font-size: 1.9375rem;
  font-weight: normal; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1.0625rem;
  margin-bottom: .1em; }

.portfolio p {
  line-height: 1.2; }

.main a {
  color: #2199e8;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer; }

.lead {
  font-size: 120%;
  text-shadow: 1px 0 1px #fff; }

.space-around {
  display: flex;
  justify-content: space-around; }

.button {
  background: linear-gradient(to bottom, #020167 0, #1C1B76 50%);
  color: #ccc;
  padding: .5rem 1em;
  border-radius: 3px;
  text-decoration: none;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 1em; }

.button:hover {
  color: #fff;
  background: #020167; }

.button:focus {
  transform: translateY(-1px); }

.button:visited {
  color: #ccc; }

.main-carousel {
  max-width: 550px;
  margin-bottom: 2rem; }

.cladding .main-carousel, .w450 {
  max-width: 450px; }

.carousel-cell {
  width: 100%; }

.carousel-cell img, .full-width {
  max-width: 100%;
  height: auto;
  display: block; }

/* Move controls to account for caption */
.cap .flickity-prev-next-button {
  transform: translateY(calc(-50% - 28px)) !important; }

.cap .flickity-page-dots {
  bottom: 0; }

.portfolio {
  display: grid;
  grid-template-columns: 1fr; }

.portfolio .alt {
  background-color: rgba(255, 255, 255, 0.3); }

.prices {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
  border-spacing: 0; }

.prices th {
  font-weight: 700; }

.prices th, .prices td {
  padding: .5rem .625rem .625rem;
  text-align: left; }

.prices td:first-child + td, .prices th:first-child + th {
  text-align: right;
  padding-right: 2em; }

.prices td {
  vertical-align: top; }

.prices tbody, thead {
  border: 1px solid #f1f1f1;
  background-color: #fefefe; }

.prices tbody tr:nth-child(odd) {
  background-color: #f1f1f1; }

@media screen and (min-width: 40em) {
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  .narrow {
    padding: 0 10%; } }

@media screen and (min-width: 800px) {
  .portfolio {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em 0;
    grid-auto-flow: dense; }
  .cladding .portfolio,
  .projects .portfolio {
    grid-template-columns: 450px 1fr;
    grid-gap: 1em 2em; }
  .full-width {
    grid-column: 1 / -1;
    margin-bottom: 1em; }
  .pictures.alt {
    grid-column: 2 / -1; }
  .desc {
    padding: 1em 0 1em 1em; }
  .pictures {
    padding: 1em 0; }
  .desc.alt {
    padding: 1em; } }
