.cards{
	display:grid;
	grid-gap:1em 3%;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	margin-bottom: 1rem;
}

.cards img{
	max-width: 100%;
	border-radius: 3px 3px 0 0
}

.cards a{
	text-decoration: none;
	border-radius: 4px;
	transition: all .5s;
	border: 1px solid #020167;
	border: 1px solid rgba(2,1,103,.3);
	background-image: linear-gradient(180deg,#f4feff 0,#e2f9ff 50%,#f4feff);
}

.cards a:hover{
	color: #020167;
	border: 1px solid #020167;
	background: #fff;
}

.card-text {
    padding: 1em 1em 0;
}

.cards h4{
	font-size: 130%;
	font-weight: normal;
	text-decoration: underline;
}

.main .card{
	color:#333;
}