*,::after,::before{box-sizing:border-box}html{font-family:sans-serif;line-height:1.15;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;-ms-overflow-style:scrollbar;-webkit-tap-highlight-color:transparent}article,aside,dialog,figcaption,figure,footer,header,main,nav,section{display:block}hr{box-sizing:content-box;height:0;overflow:visible}blockquote,dl,figure,h1,h2,h3,h4,h5,h6,ol,p,ul{margin:0 0 1rem}abbr[title]{text-decoration:underline;text-decoration:underline dotted;cursor:help;border-bottom:0}dd{margin-bottom:.5rem;margin-left:0}b,strong{font-weight:bolder}sub,sup{position:relative;font-size:75%;line-height:0;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{vertical-align:middle;border-style:none}[role=button],a,area,button,input:not([type=range]),label,select,summary,textarea{touch-action:manipulation}table{border-collapse:collapse}

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}


small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

