html{
background: linear-gradient(#020167,#f2fbff);
overflow:hidden;
}

body{
	line-height: 1.4;
	color:#333;
	margin: 0;
	height:100%;
	overflow:auto;
}

.wrapper{
	max-width: 75rem;
	margin: 0 auto;
	display:grid;
	grid-template-rows: auto auto 1fr auto;
	height:100vh;
	box-shadow:3px 3px 8px rgba(0,0,0,.1)
}

.contact-bar{
	background: #020167;
	display:flex;
	justify-content:space-between;
	padding: 0 .5rem;
	}

.contact-bar a{
	padding: .5em 0;
	display:flex;
	line-height: 1;
}

.contact-bar a, .nav a, .subnav a {
    color: #eee;
    display:inline-block;
    transform: translateZ(0);
    transition: color 1s;
}

.contact-bar .icon{
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
	position: relative;
	bottom: -0.125em;
}

.contact-bar a:hover{
	transform:scale(1.02);
	transform-origin: center center;
	color:#ff9}

.title-wrap{
	display: block;
	background: #020167 url(../images/header1.jpg) repeat 0 0 / cover;
	background-clip: border-box;
	padding: 0 .5rem;
}

.header a{
	text-decoration: none;
}
.title{
	font-family: serif;
	font-size:2.2em;
	letter-spacing:-.015em;
	font-weight: bold;
	color: #ff9;
	display:flex;
	line-height: 1;
	margin: 0; padding: 0;
	text-shadow:1px 0 1px #000;
}

.title span:nth-child(odd){
	font-size: .7em;
}
.title span:not(:nth-child(3)){
	margin-right:.3em
}
.title span:nth-child(3){
	margin-top: auto;
}
.title span:nth-child(2){
	line-height: .93;
}

.subtitle{
	font-family:Verdana, Geneva, Tahoma, sans-serif;
	color:#fff;
	font-weight: normal;
	font-size: 1.2em;
	letter-spacing:.11em;
	margin: 10px 0 0;
	padding-left:115px;
	padding-bottom: 20px;
	background: url('../images/logo.jpg') no-repeat;
}

.subtitle span{
	display: block;
}

.nav{
	list-style-type: none;
	display:grid;
	grid-template-columns: repeat(auto-fit, minmax(100px,1fr));
	background-color: #020167;
	margin: 0;padding: 0;}

.nav li{min-width:75px;}

.nav a{text-decoration: none;
	font-size: 1em;
	text-align: center;
	display:block;
	padding:.5em .5em;
	transition-duration: 300ms;
	}

.nav .active, .nav a:hover{
	background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.main{
	padding: 1em .5em;
	background: rgba(255, 255, 255, 0.8);
}

.footer{
	text-align: center;
	background: #020167;
	color:#fff;
	padding: .5em;
}

.footer a{
	color:#ccc
}

.footer-contact{
	display:flex;
	justify-content:center;
}

.footer-contact > *{
	padding: 0 2em;
}

@media screen and (min-width: 30em) {

	.nav{
		grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
	}

}

@media screen and (min-width: 40em) {

	body{
		font-size: 125%;
	}

	.main{
		padding: 1em;
	}

	.contact-bar,.title-wrap{
		padding: 0 1em;
	}

	.title{
		font-size: 3em;
		letter-spacing:0;
	}

	.subtitle{
		font-size: 1.25em;
		letter-spacing:.2em;
		margin-top: 10px;
		padding-left:130px;
	}

}
