h1, h2, h3, h4, h5 {
    font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    color: inherit;
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 1.4;
}

h3 {
    color: #020167;
    font-size: 1.9375rem;
    font-weight: normal;
}

h4 {
    font-size: 1.125rem;
}

h5 {
    font-size: 1.0625rem;
    margin-bottom: .1em;
}

.portfolio p{
	line-height: 1.2;
}

.main a{
    color: #2199e8;
    text-decoration: none;
    line-height: inherit;
    cursor: pointer;
}

.lead{
	font-size: 120%;
	text-shadow: 1px 0 1px #fff;
}

.space-around{
	display:flex;
	justify-content:space-around;
}

.button{
	background: linear-gradient(to bottom, #020167 0, #1C1B76 50%);
	color:#ccc;
	padding: .5rem 1em;
	border-radius:3px;
	text-decoration: none;
	box-shadow:3px 3px 8px rgba(0,0,0,.3);
	margin-bottom: 1em;
}

.button:hover{
	color:#fff;
	background: #020167;
}

.button:focus{
	transform: translateY(-1px);
}

.button:visited{
	color:#ccc;
}

.main-carousel{
	max-width: 550px;
	margin-bottom: 2rem;
}

.cladding .main-carousel, .w450{
	max-width: 450px;
}

.carousel-cell{
	width:100%;
}
.carousel-cell img, .full-width{
	max-width: 100%;
	height:auto;
	display:block;
}

/* Move controls to account for caption */
.cap .flickity-prev-next-button{
	transform: translateY(calc(-50% - 28px))!important;
}

.cap .flickity-page-dots{
	bottom:0;
}

.portfolio{
	display:grid;
	grid-template-columns:1fr;
}

.portfolio .alt{
	background-color: rgba(255,255,255,.3);
}

.prices {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    border-spacing: 0;
}

.prices th{
	font-weight: 700;
}

.prices th, .prices td {
    padding: .5rem .625rem .625rem;
    text-align: left;
}

.prices td:first-child + td, .prices th:first-child + th {
    text-align: right;
    padding-right: 2em;
}
.prices td {
    vertical-align: top;
}

.prices tbody, thead {
    border: 1px solid #f1f1f1;
    background-color: #fefefe;
}

.prices tbody tr:nth-child(odd) {
    background-color: #f1f1f1;
}


@media screen and (min-width: 40em) {

	h4 {
    font-size: 1.5625rem;
	}

	h5 {
    font-size: 1.25rem;
	}

	.narrow{
		padding: 0 10%;
	}

}

@media screen and (min-width: 800px) {

	.portfolio{
	grid-template-columns: 1fr 1fr;
	grid-gap:1em 0;
	grid-auto-flow: dense;
	}

	.cladding .portfolio,
	.projects .portfolio
	{
		grid-template-columns: 450px 1fr;
		grid-gap: 1em 2em;
	}

	.full-width{
		grid-column: 1 / -1;
		margin-bottom: 1em;
	}

	.pictures.alt{
		grid-column: 2 / -1
	}

	.desc{
			padding: 1em 0 1em 1em;
		}

	.pictures{
		padding: 1em 0;
	}

	.desc.alt{
		padding: 1em;
	}



}